import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  title: {
    fontSize: 12
  },
  company: {
    fontSize: 10,
    marginTop: 2,
    fontFamily: 'Rubik500'
  },
  date: {
    fontSize: 8,
    marginTop: 1,
    marginBottom: 5
  },
  location: {},
  experienceRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#c9c9c9',
    borderBottomStyle: 'dotted',
    paddingBottom: 7,
    marginBottom: 7
  }
})

export default function Experience(props) {
  if (props.formEntries) {
    styles.company.fontFamily = props.fontData?.semiBold?.name
  }

  styles.company.color = props.formEntries.color
  const experienceRows = []

  // console.log(props.experiences)
  props.experiences.forEach((item) => {
    const bullets = []

    item.bullets.forEach((bullet) => {
      if (bullet) {
        bullets.push(
          <View style={props.style.li} key={bullet}>
            <Text style={props.style.bulletPoint}>•</Text>
            <Text style={props.style.itemContent}>{bullet}</Text>
          </View>
        )
      }
      
    })

    experienceRows.push(
      <View style={styles.experienceRow} key={item}>
        <Text style={styles.title}>{item.title}</Text>
        <Text style={styles.company}>{item.company}</Text>

        <View>
          <Text style={styles.date}>
            {item.dateFrom} - {item.dateTo}
          </Text>
        </View>
        <View>
          <Text style={styles.date}>{item.details}</Text>
        </View>
        {bullets}
      </View>
    )
  })

  return (
    <View style={props.style.section}>
      <View style={props.style.sectionTitle}>
        <Text>{props.experienceTitle}</Text>
      </View>
      {experienceRows}
    </View>
  )
}
