const LOGIN_ROUTE = 'jwt-auth/v1/token'
const REGISTER_ROUTE = 'companii/v1/auth/register'
const ACTIVATE_ROUTE = 'companii/v1/auth/activate'
const RESET_ROUTE = 'companii/v1/auth/reset'
const PASSWORD_ROUTE = 'companii/v1/auth/reset/password'
const GOOGLE_ROUTE = 'companii/v1/auth/google'
import Cookies from 'js-cookie'
import { COOKIE_EXPIRY, USER_COOKIE } from '../constants'

class AuthService {
  /**
   * Login via API
   * Store user token and basic info in cookie
   * @param {*} username
   * @param {*} password
   */
  login(username, password) {
    return fetch(process.env.REACT_APP_API_URL + LOGIN_ROUTE, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 'jwt_auth_valid_credential') {
          this.#setAuthCookie(data)
        }
        return data
      })
      .catch((error) => {
        return false
      })
  }

  /**
   * Logout
   * Remove stores cookies
   */
  logout() {
    this.#removeAuthCookie()
  }

  /**
   *
   * @param {String} firstName
   * @param {String} lastName
   * @param {String} email
   * @param {String} password
   * @param {String} company
   * @returns
   */
  register(firstName, lastName, email, company) {
    return fetch(process.env.REACT_APP_API_URL + REGISTER_ROUTE, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        company: company
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        return false
      })
  }

  /**
   *
   * @param {*} email
   * @param {*} code
   * @returns Promise
   */
  activate(email, code, password) {
    return fetch(process.env.REACT_APP_API_URL + ACTIVATE_ROUTE, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        code: code,
        password: password
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        return false
      })
  }

  /**
   *
   * @param {*} email
   * @returns
   */
  reset(email) {
    return fetch(process.env.REACT_APP_API_URL + RESET_ROUTE, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        return false
      })
  }

  /**
   *
   * @param {*} email
   * @param {*} code
   * @param {*} password
   * @returns
   */
  changePassword(code, password) {
    return fetch(process.env.REACT_APP_API_URL + PASSWORD_ROUTE, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        code: code,
        password: password
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        return false
      })
  }

  googleAuth(token) {

    return fetch(process.env.REACT_APP_API_URL + GOOGLE_ROUTE, {
      method: 'post',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        token: token
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message == 'user exists' || data.message == 'user created') {
          this.#setAuthCookie(data)
        }
        return data
      })
      .catch((error) => {
        return false
      })

  }

  // ------------- Private methods
  async #setAuthCookie(data) {
    Cookies.set(USER_COOKIE, JSON.stringify(data.data), {
      expires: parseInt(COOKIE_EXPIRY),
      sameSite: 'strict'
    })
  }

  #removeAuthCookie() {
    Cookies.remove(USER_COOKIE)
  }
}

export default new AuthService()
