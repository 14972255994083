import User from './UserService'

export default function authHeader() {
  if (User && User.token) {
    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + User.token
    }
  } else {
    return { 'Content-Type': 'application/json' }
  }
}
