import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  title: {
    fontSize: 12
  },
  company: {
    fontSize: 10,
    marginTop: 2,
    fontFamily: 'Rubik500'
  },
  date: {
    fontSize: 8,
    marginTop: 1,
    marginBottom: 5
  },
  location: {},
  educationRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#c9c9c9',
    borderBottomStyle: 'dotted',
    paddingBottom: 7,
    marginBottom: 7
  }
})

export default function Education(props) {
  const educationRows = []

  props.education.forEach((edu) => {
    educationRows.push(
      <View style={styles.educationRow} key={edu}>
        <Text style={styles.company}>{edu.degree}</Text>
        <Text style={styles.title}>{edu.college}</Text>


        <View>
          <Text style={styles.date}>
            {edu.collegeFromDate} - {edu.collegetoDate}
          </Text>
        </View>
        <View>
          <Text style={styles.date}>{edu.collegeLocation}</Text>
        </View>
      </View>
    )
  })

  return (
    <View style={props.style.section}>
      <View style={props.style.sectionTitle}>
        <Text>{props.educationTitle}</Text>
      </View>
      {educationRows}
    </View>
  )
}
