import React from 'react'
import { UserContext } from '../App'
import Header from '../components/header'
import Footer from '../components/footer'
import Builder from './BuilderPage'


export default function AppPage() {
    const User = React.useContext(UserContext)
    return (
        <div className="app-container">
            <Header />
            <Builder />
            <Footer />
        </div>
    )
}