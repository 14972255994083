import React, { useContext, useEffect, useState } from 'react'

import User from '../services/UserService'
import Header from 'components/header'
import Footer from 'components/footer'

export default function ProfilePage(props) {
  const [name, setName] = useState(User?.displayName || '')
  const [email, setEmail] = useState(User?.email || '')

  const submit = () => {}

  const updateEmail = (e) => {
    setEmail(e.target.value)
  }

  const updateName = (e) => {
    setName(e.target.value)
  }

  return (
    <div className="app-container">
      <Header />
      <div className="pt-4 container page-wrapper">
        <div className="row my-1">
          <div className="col-md-5 d-flex flex-column">
            <div>
              <form onSubmit={submit} className="needs-validation">
                <h5 className="">Your information</h5>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder=""
                    value={email}
                    onChange={updateEmail}
                  />
                  <label htmlFor="email">Email Address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder=""
                    value={name}
                    onChange={updateName}
                  />
                  <label htmlFor="email">Display name</label>
                </div>
                <div className="">
                  <button className="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-5 offset-md-2 d-flex flex-column">
            <div>
              <h5 className="">Subscription</h5>
              <p>Plan: Free</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
