import Cookie from 'js-cookie'
import { USER_COOKIE } from '../constants'

class UserService {
  user = {}
  firstName = ''
  lastName = ''
  email = ''
  company = ''
  token = ''
  displayName = ''

  constructor() {
    if (!Cookie.get(USER_COOKIE)) {
      return
    }

    let user = JSON.parse(Cookie.get(USER_COOKIE))
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.email = user.email
    this.token = user.token
    this.company = user.company
    this.displayName = user.displayName
    this.user = user
  }

  authenticated() {
    if (this.token) {
      return true
    }
    return false
  }
}

export default new UserService()
