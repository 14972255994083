export const defaultExperiences = [
  //   {
  //     title: 'Senior Engineering Manager',
  //     company: 'Jungle Scout',
  //     dateFrom: '01-08-2021',
  //     dateTo: '01-08-2021',
  //     details:
  //       'Jungle Scout is a B2B and B2C SaaS providing research, analytics and various sales tools built for entrepreneurs, small business and Enterprise, selling on popular marketplaces such as Amazon and Walmart',
  //     bullets: [
  //       "Leading 2 teams of Software Engineers handling the application's membership system, checkout system, plan upgrades, lead generation and marketing initiatives",
  //       'Collaborating with various stakeholders including UI/UX designers, product managers, directors, and customer support to manage development and execution',
  //       "Responsible for the company's transaction processing system, which processes over $50M in transactions per year",
  //       'Saved the company $150,000+ in annual recurring revenue by identifying and addressing legacy issues in the registration and upgrade flows',
  //       'Worked on several projects that increased customer lifetime value, and reduced cost of customer acquisition',
  //       "Implemented improvements to the application's public API that helped the company acquire several $50,000+ Enterprise contracts"
  //     ],
  //     details:
  //       "Leading 2 teams of Software Engineers handling the application's membership system, checkout system, plan upgrades, lead generation and marketing initiatives. Collaborating with various stakeholders including UI/UX designers, product managers, directors, and customer support to manage development and execution"
  //   }
  //   {
  //     title: 'Technical Director',
  //     company: 'Queens University',
  //     dateFrom: '09-2019',
  //     dateTo: '08-2021',
  //     details:
  //       "Queen's is ranked as one of Canada's top universities. My role at Queen's was working on a platform called Elentra (elentra.org), a community-sourced teaching and learning platform, built and used by 25 medical schools across North America. Elentra is offered as open-source to Consortium members, or as a SaaS product.",
  //     bullets: [
  //       'Lead the Technical Architecture Committee, reviewed RFCs and decided on the technical direction of the platform',
  //       'Managed the core team of the platform, comprised of developers and designers',
  //       "Responsible for the company's transaction processing system, which processes over $50M in transactions per year",
  //       'Ran monthly, community-wide developer meetings with up to 80 developers, creating content and educational materials'
  //     ]
  //   },
  //   {
  //     title: 'Lead Developer',
  //     company: 'Stark Industries',
  //     dateFrom: '01-2017',
  //     dateTo: '08-2019',
  //     details:
  //       "Queen's is ranked as one of Canada's top universities. My role at Queen's was working on a platform called Elentra (elentra.org), a community-sourced teaching and learning platform, built and used by 25 medical schools across North America. Elentra is offered as open-source to Consortium members, or as a SaaS product.",
  //     bullets: ['Did some cool things']
  //   },
  //   {
  //     title: 'Lead Developer',
  //     company: 'Stark Industries',
  //     dateFrom: '01-2017',
  //     dateTo: '08-2019',
  //     details:
  //       "Queen's is ranked as one of Canada's top universities. My role at Queen's was working on a platform called Elentra (elentra.org), a community-sourced teaching and learning platform, built and used by 25 medical schools across North America. Elentra is offered as open-source to Consortium members, or as a SaaS product.",
  //     bullets: [
  //       'Lead the Technical Architecture Committee, reviewed RFCs and decided on the technical direction of the platform',
  //       'Managed the core team of the platform, comprised of developers and designers',
  //       "Responsible for the company's transaction processing system, which processes over $50M in transactions per year",
  //       'Ran monthly, community-wide developer meetings with up to 80 developers, creating content and educational materials'
  //     ]
  //   },
  //   {
  //     title: 'Lead Developer',
  //     company: 'Stark Industries',
  //     dateFrom: '01-2017',
  //     dateTo: '08-2019',
  //     details:
  //       "Queen's is ranked as one of Canada's top universities. My role at Queen's was working on a platform called Elentra (elentra.org), a community-sourced teaching and learning platform, built and used by 25 medical schools across North America. Elentra is offered as open-source to Consortium members, or as a SaaS product.",
  //     bullets: [
  //       'Lead the Technical Architecture Committee, reviewed RFCs and decided on the technical direction of the platform',
  //       'Managed the core team of the platform, comprised of developers and designers',
  //       "Responsible for the company's transaction processing system, which processes over $50M in transactions per year",
  //       'Ran monthly, community-wide developer meetings with up to 80 developers, creating content and educational materials'
  //     ]
  //   }
]
