import Footer from 'components/footer'
import Header from 'components/header'
import { useNavigate } from 'react-router-dom'
import DocumentService from 'services/DocumentService'
import ButtonSpinning from 'components/forms/buttonSpinning'
import { useState } from 'react'

export default function TemplatesPage(props) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const selectTemplate = () => {
    setLoading(true)
    DocumentService.create().then((data) => {
      if (data && data.id) {
        navigate(`/resume-builder/${data.id}`)
      } else {
        setLoading(false)
        alert('it broke yo')
      }
    })
  }

  const renderButton = (e) => {
    if (loading) {
      return <ButtonSpinning text=" Creating ..." />
    } else {
      return (
        <button onClick={selectTemplate} className="btn btn-primary">
          Template 1
        </button>
      )
    }
  }

  return (
    <div className="app-container">
      <Header />
      <div className="pt-4 container page-wrapper">
        <div className="row my-1">
          <div className="col-md-12">
            <h5>Select a template to start from</h5>
          </div>
        </div>
        <hr />
        <div className="row mt-4">
          <div>{renderButton()}</div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
