import React, { useState } from 'react'
import Button from '../../components/forms/button'
import ButtonSpinning from '../../components/forms/buttonSpinning'
import { useNavigate, useParams } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import Alert from '../../components/alert'

export default function ActivatePage(props) {
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [activated, setActivated] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const { user } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    activated && navigate('/auth/login')
  })

  const doValidate = (e) => {
    setLoading(true)
    setError(false)
    e.preventDefault()

    AuthService.activate(user, code, password).then((res) => {
      setLoading(false)
      if (res.code == 'activated') {
        setActivated(true)
      } else if (res.code == 'active_user_exists') {
        setErrorMessage('Email is already in use.')
        setActivated(false)
        setError(true)
      } else {
        setErrorMessage('Email or activation code is incorrect.')
        setActivated(false)
        setError(true)
      }
    })
  }

  return (
    <div className="container-fluid auth-container">
      <div className="row">
        <div className="col">
          <div className="auth-box">
            <h2 className="text-center">Activate your account</h2>
            <hr />
            {error && <Alert type="danger" message={errorMessage} />}
            <form onSubmit={doValidate} className="needs-validation">
              <div className="mb-3">
                <label htmlFor="code" className="form-label">
                  Activation code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  aria-describedby="help"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
                <div id="help" className="form-text">
                  Enter the activation code that we sent you by email
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Create your password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              {loading ? (
                <ButtonSpinning type="submit" text=" Activating ..." />
              ) : (
                <Button type="submit" text="Activate" />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
