import User from '../services/UserService'
import 'assets/scss/app.scss'
import image from '../assets/images/icon.png'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export default function IndexPage(props) {
  const navigate = useNavigate()

  useEffect(() => {
    if (User.authenticated()) {
      navigate('/documents')
    }
  })

  return (
    <div className="container-fluid app-container d-flex">
      <div className="row">
        <div className="col d-flex align-items-center">
          <div>
            <h2 className="text-left">Welcome to EZ Resume</h2>
            <p className="mt-3">
              Login to your account. If you don't have an account, you can
              register and start using the application{' '}
              <strong>for free!</strong>
            </p>
            <div>
              <Link to="/auth/login" className="d-grid gap-2">
                <button className="btn btn-primary" type="button">
                  Login
                </button>
              </Link>
              <Link to="/auth/register" className="d-grid gap-2 mt-2">
                <button className="btn btn-outline-primary" type="button">
                  Register
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
