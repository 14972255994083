import { defaultExperiences } from 'assets/data/experiences'
import { fonts } from 'assets/data/fonts'
import 'assets/scss/form.scss'
import React, { useContext, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Link, useParams } from 'react-router-dom'
import DocumentService from 'services/DocumentService'

const BuilderForm = ({
  onFormChange,
  onExperienceChange,
  onEducationChange,
  savedFormData
}) => {
  const [formData, setFormData] = useState([])
  const [experiences, setExperiences] = useState([])
  const [education, setEducation] = useState([])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const params = useParams()

  useEffect(() => {
    DocumentService.read(params.token).then((data) => {
      // console.log(data.data)
      if (data?.data?.formEntries) {
        setFormData(data.data.formEntries)
      }

      if (data?.data?.experiences) {
        setExperiences(data.data.experiences)
      }

      if (data?.data?.education) {
        setEducation(data.data.education)
      }
    })
  }, [])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    const newFormData = { ...formData, [name]: value }
    setFormData(newFormData)
    onFormChange(newFormData) // lifting up the state
  }

  const handleExperienceChange = (index, event) => {
    let newValues = [...experiences]
    newValues[index][event.target.name] = event.target.value
    setExperiences(newValues)
    onExperienceChange(newValues)
  }

  const handleDetailChange = (
    experience,
    experienceIndex,
    event,
    eventIndex
  ) => {
    let newValues = [...experiences]
    newValues[experienceIndex]['bullets'][eventIndex] = event.target.value
    setExperiences(newValues)
    onExperienceChange(newValues)
  }

  const handleEducationChange = (index, event) => {
    let newValues = [...education]
    newValues[index][event.target.name] = event.target.value
    setEducation(newValues)
    onEducationChange(newValues)
  }

  const submit = (e) => {
    setLoading(true)
    setError(false)
  }

  const newExperience = (e) => {
    setExperiences([...experiences, { bullets: [] }])
    e.preventDefault()
  }

  const newBullet = (experienceIndex, e) => {
    let newValues = [...experiences]
    newValues[experienceIndex]['bullets'].push('')
    setExperiences(newValues)
    e.preventDefault()
  }

  const newEducation = (e) => {
    let newValues = [...education]
    setEducation([...education, {}])
    e.preventDefault()
  }

  const moveExperience = (event, fromIndex, toIndex) => {
    event.preventDefault()

    var arr = [...experiences]
    var element = experiences[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
    setExperiences(arr)
  }

  const deleteExperience = (event, fromIndex) => {
    event.preventDefault()

    var arr = [...experiences]
    var element = experiences[fromIndex]
    arr.splice(fromIndex, 1)
    setExperiences(arr)
  }

  return (
    <form onSubmit={submit} className="needs-validation">
      {/* <div id="accordion"> */}
      <div className="card">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Appearance
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="form-group col-2">
              <label htmlFor="name" className="form-label">
                Color
              </label>
              <input
                type="color"
                className="form-control form-control-color"
                id="color"
                aria-describedby="color"
                name="color"
                value={formData?.color}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Font
              </label>
              <select
                className="form-select "
                name="font"
                onChange={handleInputChange}
                value={formData?.font}
              >
                {fonts.map((font) => (
                  <option
                    key={font.slug}
                    value={font.slug}
                  // selected={font.slug == formData.font ? 'selected' : false}
                  >
                    {font.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="card">
        <div className="card-header" id="headingTwo">
          <h5 className="mb-0">
            <button
              className="btn"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Basic Information
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          className="collapse show"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="name"
                placeholder=""
                name="name"
                value={formData?.name}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="name" className="form-label">
                Name
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder=""
                aria-describedby="title"
                name="title"
                value={formData?.title}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="title" className="form-label">
                Title
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder=""
                aria-describedby="phone"
                name="phone"
                value={formData?.phone}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder=""
                aria-describedby="email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="email" className="form-label">
                Email
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder=""
                aria-describedby="address"
                name="address"
                value={formData?.address}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="address" className="form-label">
                Address
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="summaryTitle"
                placeholder=""
                aria-describedby="summaryTitle"
                name="summaryTitle"
                value={formData?.summaryTitle}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="address" className="form-label">
                Summary Title
              </label>
            </div>

            <div className="form-floating mb-3">
              <textarea
                type="text"
                style={{ height: '150px' }}
                className="form-control"
                id="summary"
                placeholder=""
                aria-describedby="summary"
                name="summary"
                value={formData?.summary}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="summary" className="form-label">
                Objective/Summary
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="tagsTitle"
                placeholder=""
                aria-describedby="tagsTitle"
                name="tagsTitle"
                value={formData?.tagsTitle}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="tagsTitle" className="form-label">
                Tags Title
              </label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                type="text"
                style={{ height: '100px' }}
                className="form-control"
                id="tags"
                placeholder=""
                aria-describedby="tags"
                name="tags"
                value={formData?.tags}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="tags" className="form-label">
                Tags
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {formData?.experienceTitle || 'Experience'}
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="form-floating mb-3">
              <input
                type="text"
                placeholder=""
                className="form-control"
                id="experienceTitle"
                aria-describedby="experienceTitle"
                name="experienceTitle"
                value={formData?.experienceTitle}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="experienceTitle" className="form-label">
                Section title
              </label>
            </div>

            {experiences.map((experience, index) => (
              <div
                className="card mb-2 bg-light position-relative py-1"
                key={`experience` + index}
              >
                <div className="d-flex card-header py-0 align-items-center">
                  <div className="py-1">{experience.title}</div>
                  <div className="ms-auto text-right">
                    {
                      <button
                        className="btn btn-sm pull-right"
                        onClick={(event) => deleteExperience(event, index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </button>
                    }
                    {experiences.length > 1 &&
                      index < experiences.length - 1 && (
                        <button
                          className="btn btn-sm pull-right"
                          onClick={(event) =>
                            moveExperience(event, index, index + 1)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </button>
                      )}
                    {index > 0 && (
                      <button
                        className="btn btn-sm pull-right"
                        onClick={(event) =>
                          moveExperience(event, index, index - 1)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-chevron-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        id="jobTitle"
                        aria-describedby="jobTitle"
                        name={`title`}
                        value={experience.title || ''}
                        onChange={(event) =>
                          handleExperienceChange(index, event)
                        }
                      />
                      <label htmlFor="jobTitle" className="form-label">
                        Job title
                      </label>
                    </div>

                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        id="company"
                        aria-describedby="company"
                        name={`company`}
                        value={experience.company}
                        onChange={(event) =>
                          handleExperienceChange(index, event)
                        }
                      />
                      <label htmlFor="company" className="form-label">
                        Company
                      </label>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            id="dateFrom"
                            aria-describedby="dateFrom"
                            name={`dateFrom`}
                            value={experience.dateFrom}
                            onChange={(event) =>
                              handleExperienceChange(index, event)
                            }
                          />
                          <label htmlFor="dateFrom" className="form-label">
                            Start Date
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            id="dateTo"
                            aria-describedby="dateTo"
                            name={`dateTo`}
                            value={experience.dateTo}
                            onChange={(event) =>
                              handleExperienceChange(index, event)
                            }
                          />
                          <label htmlFor="dateTo" className="form-label">
                            End Date
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <textarea
                        rows="5"
                        className="form-control"
                        placeholder=""
                        id="details"
                        aria-describedby="details"
                        name={`details`}
                        value={experience.details}
                        onChange={(event) =>
                          handleExperienceChange(index, event)
                        }
                      />
                      <label htmlFor="details" className="form-label">
                        Details
                      </label>
                    </div>

                    <div className="form-group card">
                      <div className="card-body">
                        <label htmlFor="details" className="form-label">
                          Accomplishments
                        </label>
                        {experience.bullets.map((bullet, i) => (
                          <div
                            className="form-group"
                            key={`bullet[index][` + i + `]`}
                          >
                            <input
                              type="text"
                              className="form-control mb-2"
                              aria-describedby={
                                `bullet[` + index + `][` + i + `]`
                              }
                              name={`bullets[` + i + `]`}
                              value={bullet}
                              onChange={(event) =>
                                handleDetailChange(experience, index, event, i)
                              }
                            />
                          </div>
                        ))}
                        <div className="form-group">
                          <button
                            className="btn btn-light"
                            onClick={(event) => newBullet(index, event)}
                          >
                            Add bullet
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button onClick={newExperience} className="btn btn-primary">
                Add experience
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {formData?.educationTitle}
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="form-floating mb-3">
              <input
                type="text"
                placeholder=""
                className="form-control"
                id="educationTitle"
                aria-describedby="educationTitle"
                name="educationTitle"
                value={formData?.educationTitle}
                onChange={handleInputChange}
              />
              <label htmlFor="educationTitle" className="form-label">
                Section title
              </label>
            </div>

            {education.map((edu, index) => (
              <div className="card mb-2 bg-light" key={`education` + index}>
                <div className="card-body">
                  <div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        aria-describedby="degree"
                        name="degree"
                        value={edu.degree || ''}
                        onChange={(event) => {
                          handleEducationChange(index, event)
                        }}
                        required
                      />
                      <label htmlFor="degree" className="form-label">
                        Degree/Field of Study
                      </label>
                    </div>

                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        aria-describedby="college"
                        name="college"
                        value={edu.college || ''}
                        onChange={(event) => {
                          handleEducationChange(index, event)
                        }}
                      />
                      <label htmlFor="college" className="form-label">
                        School/College/University
                      </label>
                    </div>

                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        aria-describedby="college"
                        name="collegeLocation"
                        value={edu.collegeLocation || ''}
                        onChange={(event) => {
                          handleEducationChange(index, event)
                        }}
                      />
                      <label htmlFor="collegeLocation" className="form-label">
                        Location
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            aria-describedby="collegeFromDate"
                            name="collegeFromDate"
                            value={edu.collegeFromDate || ''}
                            onChange={(event) => {
                              handleEducationChange(index, event)
                            }}
                          />
                          <label
                            htmlFor="collegeFromDate"
                            className="form-label"
                          >
                            From date
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            aria-describedby="collegetoDate"
                            name="collegetoDate"
                            value={edu.collegetoDate || ''}
                            onChange={(event) => {
                              handleEducationChange(index, event)
                            }}
                          />
                          <label htmlFor="collegetoDate" className="form-label">
                            To date
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button onClick={newEducation} className="btn btn-primary">
                Add Education
              </button>
            </div>
          </div>
        </div>
      </div>

      <br />
    </form>
  )
}

export default BuilderForm
