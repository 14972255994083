import React from 'react'
import { useContext, createContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import RegisterPage from './pages/auth/RegisterPage'
import LoginPage from './pages/auth/LoginPage'
import ActivatePage from './pages/auth/ActivatePage'
import IndexPage from './pages/IndexPage'
import ResetPage from './pages/auth/ResetPage'
import PasswordResetPage from './pages/auth/PasswordResetPage'
import DocumentsPage from 'pages/DocumentsPage'

export const UserContext = createContext('unknown')

import User from './services/UserService'
import ProfilePage from './pages/ProfilePage'
import AppPage from './pages/AppPage'
import TemplatesPage from 'pages/TemplatesPage'
import { GoogleOAuthProvider } from '@react-oauth/google'

function ProtectedRoute(props) {
  if (User.authenticated) {
  }
}

function App() {
  return (
    <UserContext.Provider value={User}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route path="" element={<IndexPage />}></Route>
              <Route path="*" element={<Navigate to="/" />}></Route>
            </Route>

            {User.authenticated() && (
              <Route path="/">
                <Route path="/resume-builder/:token" element={<AppPage />} />
                <Route path="/documents" element={<DocumentsPage />} />
                <Route
                  path="/documents/templates"
                  element={<TemplatesPage />}
                />
                <Route path="/me" element={<ProfilePage />}></Route>
              </Route>
            )}

            {!User.authenticated() && (
              <Route path="/auth">
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="activate/:user" element={<ActivatePage />} />
                <Route path="reset" element={<ResetPage />} />
                <Route path="reset/:token" element={<PasswordResetPage />} />
              </Route>
            )}
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </UserContext.Provider>
  )
}

export default App
