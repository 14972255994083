import { useState } from 'react'
import authService from '../../services/AuthService'
import ButtonSpinning from '../../components/forms/buttonSpinning'
import Button from '../../components/forms/button'
import { useNavigate, Link } from 'react-router-dom'
import Alert from '../../components/alert'

export default function ResetPage(props) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const sendLink = (e) => {
    setLoading(true)
    setLinkSent(false)
    setError(false)
    e.preventDefault()

    authService.reset(email).then((res) => {
      setLoading(false)
      if (res.code == 'sent') {
        setLinkSent(true)
        setLoading(false)
      } else {
        setError(true)
        setLoading(false)
      }
    })
  }

  return (
    <div className="container-fluid auth-container">
      <div className="row">
        <div className="col">
          <div className="auth-box">
            <h2 className="text-center">Reset Password</h2>
            <hr />
            {error && (
              <Alert
                type="danger"
                message="Something is wrong. Please check your email and contact support if the problem persists"
              />
            )}
            {linkSent && (
              <Alert
                type="success"
                message="Reset link sent. Please check your email"
              />
            )}
            <form onSubmit={sendLink} className="needs-validation">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>

              {loading ? (
                <ButtonSpinning type="submit" text=" Working ..." />
              ) : (
                <Button type="submit" text="Send reset email" />
              )}
            </form>
          </div>
          <div className="text-center mt-2">
            <Link to="/auth/login" className="ps-1">
              Login to your account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
