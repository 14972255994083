import React, { useEffect, useState } from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font
} from '@react-pdf/renderer'
import 'assets/scss/builder.scss'
import ralewayFont from 'assets/fonts/raleway.ttf'
import ralewayBoldFont from 'assets/fonts/raleway-bold.ttf'
import rubik from 'assets/fonts/rubik.ttf'
import rubikBold from 'assets/fonts/rubik-bold.ttf'
import rubik500 from 'assets/fonts/rubik-500.ttf'
import Heading from './resume/heading'
import Experience from './resume/experience'

// data
import { defaultExperiences } from 'assets/data/experiences'
import { defaultData } from 'assets/data/defaultData'
import BuilderForm from './BuilderForm'
import { fonts } from 'assets/data/fonts'
import Education from './resume/education'
import DocumentService from 'services/DocumentService'
import { useParams } from 'react-router-dom'
import ButtonSpinning from 'components/forms/buttonSpinning'

fonts.map((font) => {
  Font.register({
    family: font.standard.name,
    src: font.standard.src
  })

  if (font.semiBold) {
    Font.register({
      family: font.semiBold.name,
      src: font.semiBold.src
    })
  }

  if (font.bold) {
    Font.register({
      family: font.bold.name,
      src: font.bold.src
    })
  }
})

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/'
})

export default function Builder() {
  const [formEntries, setFormEntries] = useState(defaultData)
  const [experiences, setExperiences] = useState(defaultExperiences)
  const [education, setEducation] = useState([])
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [edited, setEdited] = useState(false)

  const handleFormChange = (formData) => {
    setEdited(true)
    setFormEntries(formData)
  }

  const handleExperienceChange = (formData) => {
    setEdited(true)
    setExperiences(formData)
  }

  const handleEducationChange = (formData) => {
    setEdited(true)
    setEducation(formData)
  }

  // Create styles
  const styles = StyleSheet.create({
    page: {
      // flexDirection: 'row',
      backgroundColor: '#fff',
      fontFamily: 'Rubik',
      fontSize: 9,
      padding: 40
    },
    container: {
      flex: 1,
      flexDirection: 'row'
    },
    leftColumn: {
      flexDirection: 'column',
      width: '60%',
      paddingRight: 25
    },
    RightColumn: {
      flexDirection: 'column',
      width: '40%'
    },
    section: {
      marginBottom: 25
      // flexGrow: 1
    },
    sectionTitle: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      borderBottomColor: '#112131',
      borderBottomStyle: 'solid',
      alignItems: 'stretch',
      paddingBottom: 3,
      marginBottom: 10,
      textTransform: 'uppercase',
      fontFamily: 'Rubik500',
      fontSize: 12
    },
    name: {
      fontSize: 22,
      marginBottom: 5,
      textTransform: 'uppercase',
      fontFamily: 'RubikBold'
    },
    title: {
      fontSize: 12,
      marginBottom: 2,
      color: '#384347'
    },
    contact: {
      fontSize: 10,
      color: '#384347',
      flexDirection: 'row'
    },
    contactItem: {
      paddingRight: 15
    },
    ul: {
      flex: 1
    },
    li: {
      flexDirection: 'row',
      marginBottom: 2
    },
    bulletPoint: {
      width: 10,
      fontSize: 12
    },
    itemContent: {
      flex: 1,
      fontSize: 8
    }
  })

  const fontData = fonts.find((font) => {
    return font.slug == formEntries.font
  })

  if (fontData) {
    styles.page.fontFamily = fontData.standard.name
    styles.sectionTitle.fontFamily = fontData?.semiBold?.name
    styles.name.fontFamily = fontData?.bold?.name
  }

  const saveData = () => {
    setSaving(true)
    DocumentService.save(params.token, formEntries.title, {
      formEntries: formEntries,
      experiences: experiences,
      education: education
    }).then(() => {
      setEdited(false)
      setSaving(false)
    })
  }

  const readData = () => {}

  useEffect(() => {
    setLoading(true)
    DocumentService.read(params.token)
      .then((data) => {
        setFormEntries(data?.data?.formEntries || [])
        setExperiences(data?.data?.experiences || [])
        setEducation(data?.data?.education || [])
      })
      .then(() => {
        setLoading(false)
      })
  }, []) // Empty dependency array means this effect runs only once on component mount

  const saveButton = () => {
    if (saving) {
      return (
        <ButtonSpinning
          text=" Saving..."
          className="position-static bottom-0 right-0"
        />
      )
    } else {
      return (
        <button
          onClick={saveData}
          className={`position-fixed bottom-20 right-20 btn btn-primary ${
            edited ? '' : 'disabled'
          }`}
        >
          Save
        </button>
      )
    }
  }

  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-sm-12 d-flex justify-content-end mb-4">
          {saveButton()}
        </div>

        {loading && (
          <div className="col-md-12 text-primary text-center vh-100 d-flex align-items-center justify-content-center">
            <span
              className="spinner-grow spinner-grow-md"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}

        <div
          className={`col-md-6 form-wrapper ${
            loading ? 'visually-hidden' : ''
          }`}
        >
          {/* {formEntries && ( */}
          <BuilderForm
            onFormChange={handleFormChange}
            onExperienceChange={handleExperienceChange}
            onEducationChange={handleEducationChange}
            formData={formEntries}
            experiences={experiences}
            education={education}
          />
          {/* )} */}
        </div>

        <div
          className={`col-md-6 builder-wrapper ${
            loading ? 'visually-hidden' : ''
          }`}
        >
          <div style={{ position: 'fixed', width: '45%' }}>
            <PDFViewer
              title={formEntries.name}
              width={'100%'}
              height={'1200px'}
              showToolbar={true}
            >
              <Document>
                <Page size="A4" style={styles.page}>
                  <Heading formEntries={formEntries} style={styles} />
                  <View style={styles.container}>
                    <View style={styles.leftColumn}>
                      <Experience
                        formEntries={formEntries}
                        style={styles}
                        experienceTitle={formEntries.experienceTitle}
                        experiences={experiences}
                        fontData={fontData}
                      />

                      <Education
                        educationTitle={formEntries.educationTitle}
                        style={styles}
                        education={education}
                      />
                    </View>

                    <View style={styles.RightColumn}>
                      <View style={styles.section}>
                        <View style={styles.sectionTitle}>
                          <Text>{formEntries.summaryTitle || 'Summary'}</Text>
                        </View>
                        <View>
                          <Text>{formEntries.summary}</Text>
                        </View>
                      </View>

                      <View style={styles.section}>
                        <View style={styles.sectionTitle}>
                          <Text>{formEntries.tagsTitle}</Text>
                        </View>
                        <View>
                          <Text>{formEntries.tags}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.page.fontFamily}></View>
                </Page>
              </Document>
            </PDFViewer>
          </div>
        </div>
      </div>
    </div>
  )
}
