import { useState } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import Alert from '../../components/alert'
import Button from '../../components/forms/button'
import ButtonSpinning from '../../components/forms/buttonSpinning'
import AuthService from '../../services/AuthService'

export default function PasswordResetPage(props) {
  const [password, setpassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [error, setError] = useState(false)
  const [success, setsuccess] = useState(false)
  const { token } = useParams()
  
  const navigate = useNavigate()

  const setPassword = (e) => {
    setLoading(true)
    setError(false)
    setsuccess(false)

    e.preventDefault()

    AuthService.changePassword(token, password).then((res) => {
      setLoading(false)
      if (res.statusCode = 200 && res.code == 'success') {
        setsuccess(true)
      } else {
        setError(true)
      }
    })
  }

  return (
    <div className="container-fluid auth-container">
      <div className="row">
        <div className="col">
          <div className="auth-box animate__animated animate__fadeIn animate__faster">
            <h2 className="text-center">Set a new password</h2>
            <hr />
            {error && (
              <Alert
                type="danger"
                message="Something is wrong. Please check your password and contact support if the problem persists"
              />
            )}
            {success && (
              <Alert
                type="success"
                message="Your password has been updated!"
              />
            )}
            
            <form onSubmit={setPassword} className="needs-validation">
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  New password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  aria-describedby="passwordHelp"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  required
                />
              </div>

              {loading ? (
                <ButtonSpinning type="submit" text=" Working ..." />
              ) : (
                <Button type="submit" text="Save" />
              )}
            </form>
          </div>
          <div className="text-center mt-2">
            <Link to="/auth/login" className="ps-1">
              Login to your account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
