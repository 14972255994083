import React, { useState } from 'react'
import authService from '../../services/AuthService'
import '../../assets/scss/auth.scss'
import 'animate.css'
import ButtonSpinning from '../../components/forms/buttonSpinning'
import Button from '../../components/forms/button'
import Alert from '../../components/alert'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../App'
import { Link } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import AuthService from '../../services/AuthService'

export default function LoginPage(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setsuccess] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const user = React.useContext(UserContext)

  const navigate = useNavigate()

  React.useEffect(() => {
    loggedIn && window.location.assign('/documents')
  })

  const doLogin = (e) => {
    setLoading(true)
    setError(false)

    e.preventDefault()

    authService.login(email, password).then((res) => {
      setLoading(false)
      if (res.statusCode == 200 && res.code == 'jwt_auth_valid_credential') {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
        setError(true)
      }
    })
  }

  const renderButton = (e) => {
    if (loading) {
      return <ButtonSpinning type="submit" text=" Logging in ..." />
    } else if (success) {
      return <Button text="Logged in!" type="" />
    } else {
      return <Button type="submit" text="Login" />
    }
  }

  const handleGoogleAuth = (googleResponse) => {
    // const user = jwt_decode(googleResponse.credential)
    // const clientId = googleResponse.clientId
    const token = googleResponse.credential
    AuthService.googleAuth(token)
      .then((res) => {
        setLoading(false)
        if (res.code == 'success') {
          setLoggedIn(true)
        } else {
          setLoggedIn(false)
          setError(true)
        }
      })
  }

  return (
    <div className="container-fluid auth-container">
      <div className="row">
        <div className="col">
          <div className="auth-box">
            <h2 className="text-center">Login</h2>
            <hr />
            <div className="m-auto">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleGoogleAuth(credentialResponse)
                }}
                onError={() => {
                  alert('Google authentication failed')
                }}
              />
            </div>

            <h5 className="my-4 text-center">OR</h5>

            {error && (
              <Alert
                type="danger"
                label="error"
                message="Login unsuccessful. Please check your username and password. If the problem persists, please contact support."
              />
            )}
            <form onSubmit={doLogin} className="needs-validation">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="loginEmail"
                  aria-describedby="email"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="loginEmail" className="form-label">
                  Email address
                </label>
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>

              <div className="mb-3 form-floating">

                <input
                  type="password"
                  className="form-control"
                  placeholder=""
                  aria-describedby="loginPassword"
                  id="loginPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="loginPassword" className="form-label">
                  Password
                </label>
              </div>

              {renderButton()}
              <Link to="/auth/reset" className="d-inline-block ms-2">
                Forgot password?
              </Link>
            </form>
          </div>
          <div className="text-center mt-2">
            Not registered?
            <Link to="/auth/register" className="ps-1">
              Create a free account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
