import 'assets/scss/footer.scss'

export default function Footer() {
    return (
      <footer className="py-3 mt-4">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              Made with{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#cc0000"
                className="bi bi-heart-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                />
              </svg>
            </div>
          </div>
        </div>
      </footer>
    )
}