import React, { useEffect, useState } from 'react'
import { UserContext } from '../App'
import Header from '../components/header'
import Footer from '../components/footer'
import DocumentService from 'services/DocumentService'
import 'assets/scss/documents.scss'
import Builder from './BuilderPage'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import { ReactComponent as DuplicateIcon } from 'bootstrap-icons/icons/files.svg'
import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as DocumentIcon } from 'bootstrap-icons/icons/file-earmark-text.svg'
import ButtonSpinning from 'components/forms/buttonSpinning'
import Button from 'components/forms/button'
import Toast from 'components/Toast'

export default function DocumentsPage() {
  const User = React.useContext(UserContext)
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const docStatus = (status) => {
    return status == 1 ? 'public' : 'private'
  }

  const formatDate = (timestamp) => {
    return new Date(timestamp).toDateString()
  }

  const timeAgo = (timestamp) => {
    const now = Date.now() // current time in milliseconds
    const past = new Date(timestamp) // timestamp time
    const diffInMilliseconds = now - past // difference in milliseconds

    const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
    const diffInMinutes = Math.floor(diffInSeconds / 60)
    const diffInHours = Math.floor(diffInMinutes / 60)
    const diffInDays = Math.floor(diffInHours / 24)
    const diffInWeeks = Math.floor(diffInDays / 7)
    const diffInMonths = Math.floor(diffInDays / 30.44) // Average month length
    const diffInYears = Math.floor(diffInDays / 365.24) // Average year length considering leap year

    if (diffInYears > 0)
      return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`
    if (diffInMonths > 0)
      return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`
    if (diffInWeeks > 0)
      return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago`
    if (diffInDays > 0)
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
    if (diffInHours > 0)
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`
    if (diffInMinutes > 0)
      return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`

    return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`
  }

  const fetchAll = async () => {
    setLoading(true)
    try {
      const docs = await DocumentService.getAll()
      setDocuments(docs)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteDoc = (uuid, index) => {
    let updatedDocs = documents
    updatedDocs[index].dim = true
    setDocuments(updatedDocs)
    setLoading(true)

    DocumentService.delete(uuid).then((response) => {
      setLoading(false)
      if (response.code == 'success') {
        let updatedDocs = documents.filter((item) => item.UUID != uuid)
        setDocuments(updatedDocs)
      } else {
        let updatedDocs = documents
        updatedDocs[index].dim = true
        setDocuments(updatedDocs)
      }
    })
  }

  const duplicateDoc = (uuid) => {
    setLoading(true)
    DocumentService.duplicate(uuid).then((response) => {
      setLoading(false)
      if (response.code == 'success') {
        navigate(`/resume-builder/${response.uuid}`)
      }
    })
  }

  const dim = () => {}

  useEffect(() => {
    fetchAll()
  }, [])

  return (
    <div className="app-container">
      <Header />
      <div className="pt-4 container page-wrapper">
        <div className="row my-1">
          <div className="col-md-6 d-flex align-items-center">
            <h5 className="">Welcome, {User.displayName}</h5>
          </div>
          <div className="col-md-6 justify-content-end d-flex align-items-center">
            <Link to="/documents/templates" className="btn btn-primary">
              Create new
            </Link>
          </div>
        </div>
        <hr />
        {loading == false && documents?.length == 0 && (
          <div className="col-md-12">
            You currently don't have any documents.
          </div>
        )}
        {loading && (
          <div className="col-md-12 text-primary">
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            Loading
          </div>
        )}
        <Toast />
        <div className="row mt-4">
          {documents !== null &&
            documents.map((doc, index) => (
              <div className="col-md-6 col-lg-3 mb-3" key={doc.UUID}>
                <div className={`card ${doc?.dim ? 'dimmed' : ''}`}>
                  <div className="d-flex align-items-center highlight-toolbar ps-3 pe-2 py-2 border-0 border-top border-bottom">
                    <span className="badge bg-secondary">
                      {docStatus(doc.public)}
                    </span>
                    <div className="d-flex ms-auto">
                      <EditIcon
                        className="ms-1"
                        onClick={() => navigate(`/resume-builder/${doc.UUID}`)}
                      />
                      <DuplicateIcon
                        className="ms-1"
                        onClick={() => duplicateDoc(doc.UUID)}
                      />
                      <DeleteIcon
                        className="ms-1"
                        onClick={() => deleteDoc(doc.UUID, index)}
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <h6>
                      <Link className="name" to={`/resume-builder/${doc.UUID}`}>
                        {doc.name}
                      </Link>
                    </h6>

                    <hr />
                    <p className="mb-0 fs-7">
                      <strong>Created on</strong> {formatDate(doc.created)}
                    </p>
                    <p className="mb-0 fs-7">
                      <strong>Last edited</strong>{' '}
                      {doc.modified ? timeAgo(doc.modified) : 'NA'}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}
