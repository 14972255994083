import React, { useState } from 'react'
import authService from '../../services/AuthService'
import '../../assets/scss/auth.scss'
import ButtonSpinning from '../../components/forms/buttonSpinning'
import Button from '../../components/forms/button'
import Alert from '../../components/alert'
import { useNavigate, Link } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'

export default function RegisterPage(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('Team')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [registered, setRegistered] = useState(false)
  const navigate = useNavigate()

  React.useEffect(() => {
    registered && navigate(`/auth/activate/${email}`)
  })

  const doRegister = (e) => {
    setLoading(true)
    setError(false)
    e.preventDefault()

    authService.register(firstName, lastName, email, company).then((res) => {
      setLoading(false)
      if (res.code == 'user_exists' || res.code == 'create_success') {
        setRegistered(true)
      } else if (res.code == 'active_user_exists') {
        setErrorMessage('User already exists.')
        setRegistered(false)
        setError(true)
      } else {
        setErrorMessage(
          'Registration unsuccessful. Please check the form data and try again'
        )
        setRegistered(false)
        setError(true)
      }
    })
  }

  return (
    <div className="container-fluid auth-container">
      <div className="row">
        <div className="col">
          <div className="auth-box">
            <h2 className="text-center">Register</h2>
            <hr />
            <div className="m-auto">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleGoogleAuth(credentialResponse)
                }}
                onError={() => {
                  alert('Google authentication failed')
                }}
              />
            </div>

            <h5 className="my-4 text-center">OR</h5>

            {error && <Alert type="danger" message={errorMessage} />}
            <form onSubmit={doRegister} className="needs-validation">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="firstName"
                  placeholder=""
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
              </div>

              <div className="form-floating mb-3">

                <input
                  type="text"
                  className="form-control"
                  aria-describedby="lastName"
                  placeholder=""
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
              </div>

              <div className="mb-3">
                <label htmlFor="company" className="form-label d-none">
                  Company name
                </label>
                <input
                  type="hidden"
                  className="form-control"
                  id="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>

              <div className="form-floating mb-3">

                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  id="loginEmail"
                  aria-describedby="loginEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="loginEmail" className="form-label">
                  Email address
                </label>
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>

              {loading ? (
                <ButtonSpinning type="submit" text=" Submitting ..." />
              ) : (
                <Button type="submit" text="Register" />
              )}
            </form>
          </div>
          <div className="text-center mt-2">
            Already registered?
            <Link to="/auth/login" className="ps-1">
              Login to your account
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
