export default function Alert(props) {
  return (
    <div
      className={'alert alert-' + props.type}
      aria-label="close"
      role="alert"
    >
      {props.message}
    </div>
  )
}
