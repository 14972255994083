import rubikBold from 'assets/fonts/rubik-bold.ttf'
import ralewayFont from 'assets/fonts/raleway.ttf'
import ralewaySemiBold from 'assets/fonts/raleway-600.ttf'
import ralewayBoldFont from 'assets/fonts/raleway-bold.ttf'
import rubik from 'assets/fonts/rubik.ttf'
import rubik500 from 'assets/fonts/rubik-500.ttf'
import OpenSansSemiBold from 'assets/fonts/open-sans-500.ttf'
import OpenSansBold from 'assets/fonts/open-sans-700.ttf'

export const fonts = [
  {
    slug: 'open-sans',
    name: 'Open Sans',
    standard: {
      name: 'OpenSans',
      src: 'https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf'
    },
    semiBold: {
      name: 'OpenSansSemiBold',
      src: OpenSansSemiBold
    },
    bold: {
      name: 'OpenSansBold',
      src: OpenSansBold
    }
  },
  {
    slug: 'lato',
    name: 'Lato',
    standard: {
      name: 'Lato',
      src: 'https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf'
    },
    bold: {
      name: 'LatoBold',
      src: 'https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf'
    }
  },
  {
    slug: 'raleway',
    name: 'Raleway',
    standard: {
      name: 'Raleway',
      src: ralewayFont
    },
    semiBold: {
      name: 'RalewaySemiBold',
      src: ralewaySemiBold
    },
    bold: {
      name: 'RalewayBold',
      src: ralewayBoldFont
    }
  },
  {
    slug: 'rubik',
    name: 'Rubik',
    standard: {
      name: 'Rubik',
      src: rubik
    },
    semiBold: {
      name: 'Rubik500',
      src: rubik500
    },
    bold: {
      name: 'RubikBold',
      src: rubikBold
    }
  }
]
