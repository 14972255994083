import React from 'react'
import { UserContext } from '../App'
import { Link, useNavigate } from 'react-router-dom'
import Button from './forms/button'
import 'assets/scss/header.scss'
import { ReactComponent as AvatarIcon } from 'bootstrap-icons/icons/person-fill.svg'
import AuthService from 'services/AuthService'

export default function Header() {
  const navigate = useNavigate()

  const logout = () => {
    AuthService.logout()
    window.location.reload()
  }

  const User = React.useContext(UserContext)
  return (
    <header className="site-header mb-3">
      <div className="container slim-header">
        <div className="logo-wrap">
          <img
            src="https://ezresume.co/wp-content/uploads/2023/06/ez-resume.png"
            alt="EZ Resume Logo"
            className="logo"
          />
        </div>
        <div className="right-half">
          <Link className="nav-link" to="/documents">
            My documents
          </Link>
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AvatarIcon width="22" height="22" />
            </a>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/me">
                  Profile
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" onClick={logout} href="#">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}
