import React, { useContext } from 'react'
import { Text, View } from '@react-pdf/renderer'

export default function Heading(props) {
  props.style.title.color = props.formEntries.color

  return (
    <View style={props.style.section}>
      <Text style={props.style.name}>{props.formEntries.name}</Text>
      <Text style={props.style.title}>{props.formEntries.title}</Text>
      <View style={props.style.contact}>
        {props.formEntries.phone && (
          <Text style={props.style.contactItem}>
            📞 {props.formEntries.phone}
          </Text>
        )}

        {props.formEntries.email && (
          <Text style={props.style.contactItem}>
            📧 {props.formEntries.email}
          </Text>
        )}

        {props.formEntries.address && (
          <Text style={props.style.contactItem}>
            📍 {props.formEntries.address}
          </Text>
        )}
      </View>
      <View style={props.style.title.color}></View>
    </View>
  )
}
