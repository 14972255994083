export default function buttonSpinning(props) {
  return (
    <button type={props.type} className="btn btn-primary" disabled>
      <span
        className="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {props.text}
    </button>
  )
}
