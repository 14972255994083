import authHeader from './AuthHeaderService'

const DOCS_ROUTE = 'companii/v1/documents'

class DocumentService {
  getAll() {
    return fetch(process.env.REACT_APP_API_URL + DOCS_ROUTE + '/get_all', {
      method: 'GET',
      headers: authHeader()
    })
      .then((response) => response.json())
      .then((data) => {
        return data.docs
      })
      .catch((error) => {
        return false
      })
  }

  //   createFromTemplate(templateID) {
  //     return fetch(
  //       process.env.REACT_APP_API_URL + DOCS_ROUTE + '/create_from_template',
  //       {
  //         method: 'POST',
  //         headers: authHeader(),
  //         body: {
  //           template_id: templateID
  //         }
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         return data
  //       })
  //       .catch((error) => {
  //         return false
  //       })
  //   }

  create() {
    return fetch(process.env.REACT_APP_API_URL + DOCS_ROUTE + '/create', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        template: 'default'
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        return false
      })
  }

  save(document, title, data) {
    return fetch(process.env.REACT_APP_API_URL + DOCS_ROUTE + '/save', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        document: document,
        name: title,
        data: data
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        return false
      })
  }

  read(document) {
    return fetch(process.env.REACT_APP_API_URL + DOCS_ROUTE + '/read', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        document: document
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.log(error)
        return false
      })
  }

  delete(uuid) {
    return fetch(process.env.REACT_APP_API_URL + DOCS_ROUTE + '/delete', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        document: uuid
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.log(error)
        return false
      })
  }

  duplicate(uuid) {
    return fetch(process.env.REACT_APP_API_URL + DOCS_ROUTE + '/duplicate', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        document: uuid
      })
    })
      .then((response) => response.json())
      .then((data) => {
        return data
      })
      .catch((error) => {
        console.log(error)
        return false
      })
  }
}

export default new DocumentService()
