export const defaultData = {
  // Appearance
  color: '#1e90ff',
  font: 'rubik',
  // Basics
  name: 'Gwen Bell',
  title: 'Software Engineer',
  phone: '888-999-1212',
  email: 'name@email.com',
  address: '123 Main street',
  tagsTitle: 'Tags',
  tags: 'Project Management, Agile, Leadership, Time Management',

  // Summary & Skills
  summaryTitle: 'Summary',
  summary:
    'Results-driven Senior Engineering Manager with 10+ years of experience leading high-performing software development teams. Proven track record of delivering complex projects on time and within budget using Agile methodologies. Adept at collaborating with cross-functional teams to define and achieve strategic objectives. Strong technical background in various Web Development frameworks, with a focus on iteration, continuous improvement and innovation. Excellent communicator, skilled in mentoring and developing talent to create a culture of mutual trust, growth and success.',

  // Experience
  experienceTitle: 'Experience',

  // Education
  educationTitle: 'Education'
}
